<template>
  <div v-if="isOpen" class="cookie">
    <div class="inner">
      <div class="message">
        <p>
          {{ message }}

				<router-link class="item" to="/policy">Polityka prywatności</router-link>
        </p>

      </div>
      <div class="buttons">
        <button class="ok" @click="accept">
          {{ buttonTextAccept }}
        </button>
        <button class="deny" @click="deny">
          {{ buttonTextDeny }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { useState } from "vue-gtag-next";


export default {
  name: "CookieMessage",
  props: {
    buttonTextAccept: {
      type: String,
      default: "Zezwalaj na pliki cookie",
    },
    buttonTextDeny: {
      type: String,
      default: "Odrzuć",
    },
    message: {
      type: String,
      default: "Używamy 🍪 cookies w celu zbierania statystyk odwiedzalności.",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (!this.getGDPR() === true) {
      this.isOpen = true;
    }

    if(localStorage.getItem("GDPR:accepted") == 'yes'){
      this.enablePlugin();
    }


  },
  methods: {

 enablePlugin () {
  const { property } = useState();
      property.value = {
        id: "G-45JBY2CHMP"
      };
    },
    getGDPR() {

      // console.log('process.browser',process.browser);
      if (process.browser) {
        return localStorage.getItem("GDPR:accepted") == 'yes';
      }
    },
    accept() {
      if (process.browser) {
        this.isOpen = false;
        this.enablePlugin();
        localStorage.setItem("GDPR:accepted", 'yes');
        // this.$ga.enable();
        // this.$ga.page(this.$route.fullPath);
      }
    },
    deny() {
      if (process.browser) {
        this.isOpen = false;
        localStorage.setItem("GDPR:accepted", 'no');
        // this.$ga.disable();
      }
    },
  },
};
</script>

<style lang="less">
.cookie {
	z-index: 1;
	width: 100%;
	position: fixed;
	bottom: 0;
	background-color: #f2f2f2;

	.inner {
		max-width: var(--inner-width);
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}

	.buttons {
		button {
			margin: 10px;
			padding: 5px;
			cursor: pointer;

			background: none;
			border: 0;

			&.ok {
				color: green;
			}

			&.deny {
				color: red;
			}
		}
	}
}
</style>
