<template>
	<top v-once />

	<transition name="bread" mode="out-in">
		<breadcrumbs v-once />
	</transition>

	<main id="main">

		<div class="in">

		<div class="catmenu" v-if="$route.meta.leftmenu">
		<LeftCategories />
		</div>

			<router-view v-slot="{ Component }">
				<!-- <transition name="page" mode="out-in"> -->
					<keep-alive>
						<component v-bind:is="Component" :key="$route.fullPath" />
					</keep-alive>
				<!-- </transition> -->
			</router-view>
		</div>
	</main>

	<bottom v-once />

	<cookie />

</template>

<script>



// import { mapState } from 'vuex';
import { onMounted } from 'vue';
import store from './store';
import cookie from "./components/cookie.vue";
import top from './components/top.vue';
import bottom from './components/bottom.vue';
import search from './components/search.vue';
import breadcrumbs from './components/breadcrumbs.vue';
import LeftCategories from '@/components/left-categories.vue';

export default {

	data() {
		return {
			loading: true,
			error: false,
			msg: '',
			config: false,

		};
	},

	components: {
		top,
		bottom,
		breadcrumbs,
		LeftCategories,
		cookie
	},

	computed: {


		/*
		...mapState({
			customers: (state) => {
				state.customer.customers;
				console.log('state.customer.customers', state.customer.customers);
			},
		}),
		*/
	},

	mounted() {
		//console.log('-- moutned',process.env);
	},

	// https://bernar.do/vuex-store-with-normalized-data-using-a-laravel-backend

	created() {
		//console.log('-- created');
		/*
			console.log('this.$config',JSON.stringify(this.$config));

			store.dispatch('fetchCustomers').then(() => {
				this.loading = false;
			});

			*/
	},



	watch: {
		$route(to, from) {
			// console.log('watching route', to, from);

			const toDepth = to.path.split('/').length;
			const fromDepth = from.path.split('/').length;

			this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
		},
	},
};
</script>

<style lang="less">
@import url('./style/global.less');

#app {
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.page-enter-active {
	transition-duration: 500ms;
}
.page-leave-active {
	transition-duration: 500ms;
	/*position: absolute; */
}

.page-enter-from {
	opacity: 0;
	/* transform: translateX(-100px); */
}

.page-enter-to {
	opacity: 1;
	/* transform: translateX(0); */
}

.page-leave-from {
	opacity: 1;
	/* transform: translateX(0px); */
}

.page-leave-to {
	opacity: 0;
	/* transform: translateX(100px); */
}

.bread-enter-active {
	transition-duration: 500ms;
	overflow: hidden;
	transition-delay: 100ms;
}
.bread-leave-active {
	transition-duration: 500ms;
	overflow: hidden;
	/*position: absolute; */
}

.bread-enter-from {
	opacity: 0;
	transform: scaleY(0);
	max-height: 0px;
	/* transform: translateX(-100px); */
}

.bread-enter-to {
	opacity: 1;
	transform: scaleY(1);
	max-height: 100px;
	/* transform: translateX(0); */
}

.bread-leave-from {
	opacity: 1;
	transform: scaleY(1);
	max-height: 100px;
	/* transform: translateX(0px); */
}

.bread-leave-to {
	opacity: 0;
	transform: scaleY(0);
	max-height: 0;
	/* transform: translateX(100px); */
}
</style>
