function Process(whole) {


	// console.log('whole', whole)

	if (whole && whole.items) {

		whole.itemtocat = {};

		for (let key in whole.items) { // items

			if (whole.items[key].pub == false) { // usuń nieopublikowane
				delete whole.items[key];
				continue;
			}

			if (whole.items[key].category) { // jeśli item ma jakąś categorie

				let itemcats = whole.items[key].category.length;

				if (itemcats) { // item ma kategorie

					for (let i = 0; i < itemcats; i += 1) {

						let itemCat = whole.items[key].category[i];


						if (!whole.categories[itemCat].contain) {
							whole.categories[itemCat].contain = []
						}


						whole.categories[itemCat].contain.push(key);

					}
				}
			}

		}
	}

	if (whole && whole.categories) {

		for (let cat in whole.categories) { // iteruj kategorie

			//if (cat == 'people') {

			if (whole.categories[cat].contain) { // coś tam wogóle jest
				whole.categories[cat].groups = makeItemsGroups(whole.categories[cat].contain, whole.items); // zrób grupy jeśli ludzue
			}

			//} // nie tylko people



			/*
			if (whole.categories[cat].tags) {

				for (let item in whole.categories[cat].tags) {

					if (whole.itemtocat[item]) {

						whole.categories[cat].tags[item].contains = whole.itemtocat[item];

						whole.categories[cat].contains = whole.itemtocat[item];

					} else {
						// delete whole.categories[cat].items[item]
					}
				}


			}
*/








		}

	}

	// console.log('processs', whole)

	return (whole);

}



function makeItemsGroups(garray, items) {

	if (!garray || garray.length === 0) {
		return ([])
	}

	let itemsArray = []

	// zrób tymczasowa tablice

	for (let i = 0, it = garray.length; i < it; i += 1) {

		let itemObj = items[garray[i]];

		let groupObj = { 'name': itemObj.name, 'slug': garray[i], 'tag': itemObj.tag, 'show': true, 'sort': itemObj.sort }

		itemsArray.push(groupObj);
	}

	// console.log('itemsArray', itemsArray);

	// posortuj ta tymczasoea tablice
	itemsArray.sort(function (a, b) {

		let comp = a.sort.localeCompare(b.sort);

		// console.log('comp', comp);

		return (comp);

		/*
		if (a.sort > b.sort) {
			return 1
		}
		if (a.sort > b.sort) {
			return 0
		}
		return -1
		*/
	});

	let ites = itemsArray.length;


	let groups = {};

	for (let i = 0; i < ites; i += 1) {

		let key = itemsArray[i].sort[0].toLowerCase();

		// itemsArray[i].name = itemsArray[i].name.split(', ').reverse().join(' ');

		if (groups[key]) { // ta grupa juz jes
			groups[key].push(itemsArray[i]);
		} else {
			groups[key] = [itemsArray[i]];
		}

		groups[key].show = true

	}
	return (groups)

}

function makeBandGroups(garray) {

	garray.sort(function (a, b) {
		if (a.name[0] > b.name[0]) {
			return 1
		}
		if (a.name[0] > b.name[0]) {
			return 0
		}
		return -1
	});

	let ites = garray.length;

	if (ites) {

		let groups = {};

		for (let i = 0; i < ites; i += 1) {

			let key = garray[i].name[0];

			// garray[i].name = garray[i].name.split(', ').reverse().join(' ');

			if (groups[key]) { // ta grupa juz jes
				groups[key].push(garray[i]);
			} else {
				groups[key] = [garray[i]];
			}
		}

		return (groups)
	}

	return (false)
}



export default Process;
