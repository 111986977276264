<template name="category" v-once :key="$route.fullPath">
	<keep-alive>
		<div class="catout">

		<div :class="'category-'+cat + ' category'">

			<div v-if="!$dat.categories[cat].title">Loading title...</div>
			<h1 v-else v-once><span class="name">{{ $dat.categories[cat].title }}</span></h1>

			<form hidden id="filterItems">
				<template
					v-for="(item, key) of $dat.categories[cat].tags"
					v-bind:key="key"
				>
					<label
						><input
							:checked="key == filterName"
							:name="cat"
							:value="key"
							type="radio"
							v-model="filterName"
						/><span class="filtr">{{ item.title }}</span></label
					>
				</template>

				<label
					><input
						:checked="filterName == 'all'"
						:name="cat"
						value="all"
						type="radio"
						v-model="filterName"
					/><span>wszystkie</span></label
				>
			</form>



			<div v-if="listEmpty">
				<transition name="group"><p v-if="listEmpty">no items</p></transition>
			</div>

			<dl v-else class="groups text">

				<template v-for="(item, key) of groups" v-bind:key="cat+''+key">

					<!-- <transition name="group"> -->
						<div class="group-contain" v-if="item.show">
							<dt class="group-title">
								<span class="title">{{ key }}</span>
							</dt>
							<dd class="group-items">
								<ul class="group">
									<!-- <transition-group name="list"> -->
										<template v-for="values of item" v-bind:key="values.slug">
											<li
												v-if="values.show"
												class="group-item"
												:data-tag="values.tag ? values.tag.join(' ') : ''"
											>
												<router-link
													class="item"
													:to="$route.path + '/' + values.slug"
												>
												<!--
													<img
														class="pic notloaded"
														loading="lazy"
														:src="
															'photo/' +
															(values.image
																? values.image
																: 'default-' + cat + '.svg')
														"
														:alt="values.name"
														@load="lazyload"
													/>
													-->

													<span class="name">{{ values.name }}</span>
												</router-link>
											</li>
										</template>
									<!-- </transition-group> -->
								</ul>
							</dd>
						</div>
					<!-- </transition> -->
				</template>
			</dl>
		</div>
</div>
	</keep-alive>
</template>

<script>
// import licomponent from '../components/licomponent.vue';

// import LeftCategories from '@/components/left-categories.vue';

// console.log('licomponent', licomponent);

export default {
	name: 'category',
// components: { LeftCategories },
	props: {
		cat: String,
	},

	data() {
		return {
			categoryClass: this.cat,
			categoryTitle: false,
			filterName: '',

			catmenu: true,

			groups: false,
			listEmpty: false,
		};
	},

	filters: {
		slug: function (value) {
			console.log('slug', value);
		},
	},
	methods: {

		lazyload(e) {
			e.target.classList.toggle('notloaded');
		},
		filterItems() {
			//			console.log('this.filterName', this.filterName);

			for (let g in this.groups) {
				let it = this.groups[g].length;

				this.groups[g].show = true;

				let somethinInIt = false;

				for (let i = 0; i < it; i += 1) {
					this.groups[g][i].show = true;

					if (this.filterName == 'all') {
						somethinInIt = true;
						this.listEmpty = false;
						continue;
					}

					if (this.groups[g][i].tag) {
						if (!this.groups[g][i].tag.includes(this.filterName)) {
							// jesli nie ma takiego tagu w tagach
							this.groups[g][i].show = false;
							this.listEmpty = true;
						} else {
							somethinInIt = true;
							this.listEmpty = false;
							// jest chociaz jedna jest
						}
					}
				}

				if (somethinInIt == false) {
					this.listEmpty = true;
					this.groups[g].show = false;
				}
			}

			// console.log('empty', this.listEmpty);

			// Vue.set(this.groups, 'address', '123th avenue.');
		},

		forceUpdate(cat) {

			this.groups = null;
			let currentCatObj = null;

			console.log('im forcing update',this.cat, cat ,this.$route);

			if (!this.filterName) {
				this.filterName = 'all';
			}

		currentCatObj = this.$dat.categories[cat];

			this.groups = currentCatObj.groups;
			this.categoryTitle = currentCatObj.title;
		},
	},
	watch: {
		// to jest koszmar trzeba wymusić odświeżenie danyuch bo tak


		$route(to, from) {

// 			console.log('watch orte to',to, this.$dat.categories[to.matched[0].props.default.cat])

			if (to && to.matched.length && this.$dat.categories[to.matched[0].props.default.cat]) {

				let targetCat = to.matched[0].props.default.cat;

				//if (this.cat != targetCat) {

					this.forceUpdate(targetCat);

				//}
			}
			// react to route changes...
		},


		// whenever question changes, this function will run
		filterName(prev, now) {
			this.filterItems();
			// console.log('watch', prev, now);
		},
	},
	computed: {
		/*
		filteredImages: function() {
			if (this.galleryFilter === 'all') {
				return this.images;
			} else {
				return this.images.filter(
					(image) => image.filter === this.galleryFilter
				);
			}
		},
		*/
	},

	created() {
		// console.log('to created');

		this.$store.catmenu = true;

		console.log('to mounted', this.$store.catmenu);

		this.forceUpdate(this.cat);

		/*
		if (!this.filterName) {
			this.filterName = 'all';
		}
		let currentCatObj = this.$dat.categories[this.cat];
		this.groups = currentCatObj.groups;
		this.categoryTitle = currentCatObj.title;
		*/

		// console.log('this tutaj', this);
		//console.log('teraz this',this.cat,this.$dat);
		//console.log('created')
		// this.$store.dispatch('getCategory', { "category": this.cat }) // odpali go jeszcze raz na siłę?
	},

	beforeCreate() {
		// console.log('to before created');
		// console.log('filter name przyu create', this.filterName);
		// this.$store.dispatch('getCategory', { "category": this.cat }) // odpali go jeszcze raz na siłę?
		// console.log('beforeCreate')
	},

	beforeRouteUpdate() {
		console.log('beforeRouteUpdate beforeRouteUpdate beforeRouteUpdate');
	},

	/// kłeh ale jak ktoś wejdzie tu bezposrednio to nie uruchomi routera i dane sie nie zaladuja?

	mounted() { 



		// console.log('teraz mounted');


	},
};
</script>

<style lang="less">
.category-people {
	h1 {
		background-color: var(--people-back);
		&::before {
			background-color: var(--people-front);
		}
	}

	.group-title {
		color: var(--people-back);
	}
}

.category-band {
	h1 {
		background-color: var(--band-back);
		&::before {
			background-color: var(--band-front);
		}
	}
	.group-title {
		color: var(--band-back);
	}
}

.category-place {
	h1 {
		background-color: var(--place-back);
		&::before {
			background-color: var(--place-front);
		}
	}
	.group-title {
		color: var(--place-back);
	}
}

.category-media {
	h1 {
		background-color: var(--media-back);
		&::before {
			background-color: var(--media-front);
		}
	}
	.group-title {
		color: var(--media-back);
	}
}

.category {
	h1 {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-bottom: 20px;
		margin-top: 0;
		text-align: right;
		color: white;
		font-weight: bold;
		font-size: 3rem;
		position: relative;
		overflow: hidden;
		text-transform: lowercase;

		.name {
			position: relative;
		}

		&::before {
			content: '';
			width: 300px;
			height: 100%;

			display: block;
			position: absolute;
			right: 0px;
			top: 0;
			z-index: 0;
			mask-position: center;
			mask-image: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 1) 100%
				),
				url('../assets/title-mask.png');
			mask-size: cover;
		}
	}
}

.catout {
	flex-grow: 1;
}

.catmenu {
}

.category {
}

.groups {
	column-count: 2;

	.group-title {
		font-size: 2rem;
		font-weight: bold;
		page-break-after: avoid;
		text-transform: uppercase;
	}

	.group-items {
		margin: 0;
		padding: 0;
		page-break-before: avoid;
		page-break-inside: avoid;

		.group {
			display: block;
		}
	}

	.group-contain {
		margin-bottom: 20px;
		page-break-inside: avoid;
	}
}
</style>

