<template>
	<div id="bottom">
		<footer class="in">
			<div class="inner">

			</div>

			<div class="copy">

				<a href="http://muzeum.grudziadz.pl/" class="item">

				<img loading="lazy" decoding="async" src="@/assets/mlogo.png">

				Copyright © {{ new Date().getFullYear() }} Muzeum im. ks. dr. Władysława
				Łęgi w Grudziądzu
				</a>


				<router-link class="item" to="/policy">Polityka prywatności</router-link>

<a href="https://grudziadz.pl/" class="item">
				<img loading="lazy" decoding="async" src="@/assets/herb.png">

				Projekt sfinansowany ze środków Gminy Miasta Grudziądz
</a>




			</div>

		</footer>
	</div>
</template>

<script>


export default {
	name: 'bottom'
};
</script>

<style lang="less">
@import url(../style/mediaqueries.less);

#bottom {
	background-color: var(--footer-backcolor);
	padding-bottom: 10px;
	margin-top: 20px;

	.inner {
		display: flex;
		flex-direction: row;
	}

	a {
		text-decoration: none;
	}
}

.copy {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 80px;
	font-size: 0.9rem;

	@media @tablet {
		flex-wrap: wrap;
	}

	.item {
		vertical-align: middle;
		color: black;
	}

	img {
		max-height: 30px;
		vertical-align: middle;
	}
}
</style>
