<template>
	<div hidden v-if="crumbs" id="breadcrumb">
		<nav class="in">
			<ol aria-label="Breadcrumb">
				<template v-for="crumb in crumbs" v-bind:key="crumb.to">
					<li>
						<router-link class="item" v-bind:to="crumb.to">
							{{ crumb.text }}
						</router-link>
					</li>
				</template>
			</ol>
		</nav>
	</div>
</template>
<script>
export default {
	data() {
		return {
			existingRoutes: {},
			homeName: {},
		};
	},

	created() {
		this.homeName = this.$router.resolve('/').name;

		let allRoutes = this.$router.options.routes;

		//  console.log('jeden hej ?');
		//  console.log('this.$route', this.$router.options.routes);

		for (let r = 0, rs = allRoutes.length; r < rs; r += 1) {
			this.existingRoutes[allRoutes[r].path.substr(1)] = 'dupa';

			// console.log(allRoutes[r]);
		}
	},
	computed: {
		crumbs: function() {
			if (this.$route.meta.home) {
				return false;
			}

			if (this.$route.matched.length === 0) {
				return false;
			}

			if (this.$route.matched[0].props.default.errorText) {
				return [
					{
						path: '/',
						to: '/',
						text: this.homeName,
					},
				];
			}

			// console.log('crumbs this', this.$route);

			let pathArray = this.$route.path.split('/');

			// pathArray[0] = this.$router.resolve('/').name;

			pathArray.shift();

			pathArray.pop(); // usun oistatni element

			let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
				//					console.log('idvc', this.$route.matched[idx]);

				let text = path;

				if (
					this.$route.matched[idx] &&
					this.$route.matched[idx].meta &&
					this.$route.matched[idx].meta.breadCrumb
				) {
					text = this.$route.matched[idx].meta.breadCrumb;
				}

				breadcrumbArray.push({
					path: path,
					to: breadcrumbArray[idx - 1]
						? '/' + breadcrumbArray[idx - 1].path + '/' + path
						: '/' + path,

					text: text,
				});

				return breadcrumbArray;
			}, []);

			// //				console.log('wwwwwww', breadcrumbs);

			// no dobra tu troszkę - bo w /costam jest tylko jeden i to jest home

			// dodaj home na poczatek

			breadcrumbs.unshift({
				path: '/',
				to: '/',
				text: this.homeName,
			});

			return breadcrumbs;
		},
	},
};
</script>
<style lang="less">
#breadcrumb {
	nav {
		display: block;
	}

	ol {
		display: flex;
		justify-content: center;
		list-style: none;
	}

	li {
		&:before {
			content: '\ >\ ';
		}

		&:first-child {
			&::before {
				content: '';
			}
		}
	}

	a {
		padding: 10px;
	}
}
</style>
