import { createStore, createLogger } from 'vuex'

// import customer from './customers'

import axios from 'axios'


function makeGroups(array) {

  array.sort(function (a, b) {
    if (a.name[0] > b.name[0]) {
      return 1
    }
    if (a.name[0] > b.name[0]) {
      return 0
    }
    return -1

  });

  let ites = array.length;

  if (ites) {

    let groups = {};

    for (let i = 0; i < ites; i += 1) {

      let key = array[i].name[0];

      array[i].name = array[i].name.split(', ').reverse().join(' ');

      if (groups[key]) { // ta grupa juz jes
        groups[key].push(array[i]);
      } else {
        groups[key] = [array[i]];
      }
    }

    return (groups);

  }

  return ([])


}

// https://blog.logrocket.com/how-to-consume-apis-with-vuex-and-axios/
// import axios from 'axios'


/*
axios.interceptors.response.use(
  function (response) { return response; },
  function (error) {
    // handle error
    if (error.response) {
      alert(error.response.data.message);
    }
  });
  */




/*
// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});
*/

// one są te same ale rozmemłane tak każdy osobno jest

//to handle state
const state = {
  categories: {}
}


//to handle state
const getters = {}

//to handle actions
const actions = {

  async getPosts({ commit }, payload) {

    let params = '';


    if (payload) {
      params = 'program=data';
    }

    console.log('commit w posts', payload);

    // https://jsonplaceholder.typicode.com/posts
    await axios.get('https://localhost/php/vue3/public/api.php?' + params).catch((error) => {
      commit('ERROR_POSTS', error.message);
      return (false);
    }).then(
      response => { commit('SET_POSTS', response.data) },
      rejected => { commit('ERROR_POSTS', rejected) }
    )
  },

  async getConfig({ commit }) {
    // https://jsonplaceholder.typicode.com/posts
    await axios.get('https://localhost/php/vue3/public/api.php').catch((error) => {
      commit('ERROR_CONFIG', error.message);
      return (false);
    }).then(
      response => { commit('GET_CONFIG', response.data) },
      rejected => { commit('ERROR_CONFIG', rejected) }
    )
  },

  getCategory({ commit }, payload) {

    // console.log('no wez', JSON.stringify({ commit }));

    // https://jsonplaceholder.typicode.com/posts
    axios.get('https://localhost/php/vue3/public/api.php?category=' + payload.category).catch((error) => {
      commit('ERROR_CATEGORY', error.message);
      Promise.reject('nie nie nie')
      // return (false);
    }).then(
      response => {

        if (response) {

          if (response.data.error) {
            commit('ERROR_CATEGORY', response.data.error)
          } else {

            // console.log('response', response);

            let resp = {}

            if (response.data && response.data.data.items) {

              let key = response.data.key

              resp.key = key

              resp.load = response.data.data;

              if (key == 'people') {
                resp.load.groups = makeGroups(response.data.data.items)
              }

              if (key == 'band') {
                resp.load.groups = makeGroups(response.data.data.items)
              }

              // console.log('tu this', this, state);

              commit('GET_CATEGORY', resp)

              // Promise.resolve('ale fajnie się udało')
              // return ('ale super 1')
            }

          }
        } else {
          // Promise.reject('nie nie nie')
          commit('ERROR_CATEGORY', 'no pusta przyszla co zrboic')
          // Promise.reject('nie nie nie')
        }
      },
      rejected => {
        // console.log('request rejected!')
        //Promise.reject('nie nie nie')
        commit('ERROR_CATEGORY', rejected)

      }
    )
  }

}

//to handle mutations
const mutations = {

  SET_POSTS(state, posts) {
    state.posts = posts
  },

  ERROR_POSTS(errorMsg, dupa) {
    state.posts = null
    state.error = dupa
  },

  GET_CONFIG(state, conf) {
    state.config = conf;
  },

  ERROR_CONFIG(errorMsg, dupa) {

  },

  GET_CATEGORY(state, category) {

    // console.log('category category', category);

    // state.categories[category.key] = category.load;

    // console.log('co to w kategorii?', JSON.stringify(state), category);

    /*
    if (category.data && category.data.items) {
      // console.log(category.data.items)
      state.categories[category.key] = category.data;
      if (category.key == 'people') {
        state.categories[category.key].groups = makeGroups(category.data.items)
      }
      if (category.key == 'band') {
        state.categories[category.key].groups = makeGroups(category.data.items)
      }
   }
    */

    // Promise.resolve('suer ale fajnie się udało')
    // return ('ale super')

  },

  ERROR_CATEGORY(errorMsg) {

    // console.error('get categoryu error', JSON.stringify(errorMsg));
    // Promise.reject('reject get categoryu error' + JSON.stringify(errorMsg))
    // Promise.reject('NIE bo nie')
    // return false

  },

}

const modules = {
  //  customer,
}

// export default makeGroups;


export default createStore({
  strict: true,
  // @ts-ignore
  state,
  // @ts-ignore
  mutations,
  // @ts-ignore
  actions,
  // @ts-ignore
  modules,
  plugins: [createLogger({
    collapsed: true, // auto-expand logged mutations
    logActions: true, // Log Actions
    logMutations: true, // Log mutations
    logger: console, // implementation of the `console` API, default `console`
  })]


})