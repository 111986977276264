import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import titleMixin from './mixins/title'
import slug from './mixins/slug'
import axios from 'axios';
import process from './mixins/process.js';
import VueGtag from "vue-gtag-next";
// import vueAnalytics from "vue-analytics";

// import CoolLightBox from 'vue-cool-lightbox'
// import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'


axios.interceptors.request.use(function (config) {
	config.headers.Accept = 'application/json';
	// forbidden header
	// config.headers['Sec-Fetch-Dest'] = 'script';
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});


const vueapp = createApp(App)

vueapp.config.globalProperties.$filters = {
	nl2br(value) {
		return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
	},
	nl2p(value) {
		return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<p>' + '' + '$2<p>');
	},
	format(value) {
		return (value + '').replace(/\_([^\_]*)\_/g, '<em>$1</em>').replace(/\*([^\*]*)\*/g, '<strong>$1</strong>');
	},
	makeLink(value) {
		return (value + '').replace(/@([^\s]*)\s`([^`]*)`/g, '<a href="#$1">$2</a>').replace(/\s*(http[^\s]*)\s*/g, ' <a href="$1">$1</a> ');
	},
	makeOnlyLink(value) {

		let val = ''

		if (value[0] === 'h') { // czy to http?
			val = '<ul class="url">' + (value + '').replace(/(http[^ \\]*) ([^\n]*)/gum, '<li><a href="$1">$2</a></li>') + '</ul>'
		} else {
			val = this.format(value);
		}

		return val;

	}
};


// https://v3.vuejs.org/api/application-config.html#optionmergestrategies



vueapp.config.performance = true;


// zaściągnij config już teraz i bez pośrednicewa vuex ponieważ bo tak będzie
// szybiej?
// ale asynchrinicznieu

// console.log(process.env.VUE_APP_VERSION)

let apiEndpoint = 'https://grudziadzkastrefakultury.pl/api.php';

// console.log('window.location.hostname', window.location.hostname);

if (window.location.hostname == 'localhost') {
	apiEndpoint = 'http://localhost/php/vue3/public/api.php';
}



axios.get(/* webpackPrefetch: true */apiEndpoint + '?program=config').catch(error => {

	let appElem = document.getElementById('app')
	document.body.classList.add('error');
	let errorMsg = document.createElement('div');
	errorMsg.classList.add('error');
	errorMsg.innerText = error.message
	appElem.appendChild(errorMsg);
	// console.log(appElem);
	return (Promise.reject())

}).then(response => {

	vueapp.config.globalProperties.$conf = response.data;

}).then((resolved) => {

	// console.log('yea', resolved, apiEndpoint);

	axios.get(apiEndpoint + '?whole=yes&date=' + Date.now()).catch((error) => {
		console.log(error)
	}).then(response => {
		vueapp.config.globalProperties.$dat = process(response.data);
		// console.log('ggg', vueapp.config.globalProperties.$dat)
		vueapp.mixin(titleMixin).mixin(slug).use(store).use(router);
		// vueapp.mixin(titleMixin).mixin(slug).use(router);

		//console.log(router);





		vueapp.use(VueGtag, {
			/*
			property: {
				id: "UA-5576332-2" // smdm
			},
			*/
			/*enabled: false, */
			/* bootstrap: false, */
			router,
			useDebugger: false
		});

		if (localStorage.getItem("GDPR:accepted") == 'yes') {

		}




		vueapp.mount('#app'); // hłeh

	});

}, (rejected) => {
	// console.log('rejected', rejected)
});

// vueapp.config.globalProperties.$config = { 'mazwa': 'gle', 'miazga': 'weee' };
// vueapp.config.errorHandler = (err, vm, info) => {}
// vueapp.config.warnHandler = function (msg, vm, trace) {}





// to nie wygląda ładnie


//console.log('hej uruicghonile config')
// this.loading = false;

// createApp(App).mixin(titleMixin).use(store).use(router).mount('#app')


// console.log(App);



export default {
	onRenderTriggered(e) {
		console.log('onRenderTriggered', e);

		// inspect which dependency is causing the component to re-render
	}
}