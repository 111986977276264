<template>
			<ul class="leftcat">
				<li :class="'group-item'" v-for:="(key, index) of $dat.categories">
					<router-link
						:class="'item '+index"
						:to="'/'+ key.path"
					>

					<span class="bull"></span>
						<span class="name">{{ key.name }}</span>
					</router-link>
				</li>
			</ul>

</template>

<script>
export default {
	name: 'leftCategories',
	data() {
		return {};
	},
};
</script>

<style lang="less">
@import url(../style/mediaqueries.less);
.leftcat {
	display: flex;
	flex-direction: column;

	gap: 10px;

	@media @tablet {
		flex-wrap: wrap;
		flex-direction: row;
	}

	.people .bull {
		background-color: var(--people-back);

		&:before {
			background-color: var(--people-front);
		}
	}
	.band .bull {
		background-color: var(--band-back);
		&:before {
			background-color: var(--band-front);
		}
	}

	.place .bull {
		background-color: var(--place-back);
		&:before {
			background-color: var(--place-front);
		}
	}

	.media .bull {
		background-color: var(--media-back);
		&:before {
			background-color: var(--media-front);
		}
	}

	@keyframes back {
		from {
			background-position: 0% 0%;
		}

		to {
			background-position: 100% 0%;
		}
	}

	.bull {
		display: block;
		position: relative;

		min-height: 120px;
		min-width: 120px;
		/* overflow: hidden; */
		/*min-width: 100%; */

		&:before {
			pointer-events: none;
			opacity: 1;

			content: '';
			width: 100%;
			height: 100%;

			background-image: linear-gradient(
				80deg,
				transparent 30%,
				rgba(255, 255, 255, 1) 50%,
				transparent 70%
			);

			background-position: 0% 0%;
			background-size: 400% 100%;

			animation-fill-mode: forwards;
			animation-duration: 500ms;

			display: block;
			position: absolute;
			z-index: 0;
			mask-position: center;
			mask-image: url('../assets/mask.png');

			mask-size: 130px;
		}
	}

	.item {
		display: block;
		text-decoration: none;
		color: inherit;
		position: relative;

		&:hover {
			.bull {
				&::before {
					animation-name: back;

					/* background-position: 100% 0%; */
				}
			}
		}

		.name {
			text-transform: lowercase;
			font-weight: bold;
			position: absolute;
			font-size: 2.1rem;
			color: white;
			bottom: 5px;
			right: 5px;
		}
	}
}
</style>
