<template>

			<router-link to="/" id="logo">

			<span class="rect">
				<span class="r1"></span>
				<span class="r2"></span>
				<span class="r4"></span>
				<span class="r5"></span>
				<span class="r7"></span>
				<span class="r6"></span>
				<span class="r3"></span>
			</span>
			<span class="text">
				<span class="g">Grudziądzka </span>
				<span>strefa kultury</span>
				</span>

			</router-link>

</template>

<script>


export default {
	name: 'logo',
	components: {  },
};
</script>

<style lang="less">
@import url(../style/mediaqueries.less);

#logo {
	flex-shrink: 0;

	padding-right: 10px;
	position: relative;
	display: flex;
	align-items: center;

	.text {
		@media @tablet {
			display: none;
		}
		font-size: 1rem;

		text-transform: uppercase;
		text-align: justify;
		line-height: 0.8;
		font-weight: bold;
		letter-spacing: 0.19rem;

		.g {
			font-size: 1.7rem;
			letter-spacing: 0;
			&:after {
				content: '';
				display: block;
				line-height: 0;
				height: 0;
			}
		}
	}

	.rect {
		position: relative;
		display: block;
		/* transform: scale(0.3); */
		flex-basis: 90px;
		width: 90px;
		height: 80px;

		span {
			position: absolute;
			border-radius: 5px;
			/* width: 60px; */
			width: 20.07815px;
		}

		.r1 {
			background-color: #ffcc00;
		}

		.r2 {
			background-color: #97a49a;
		}

		.r3 {
			background-color: #a3d9f7;
		}

		.r4 {
			background-color: #e9556f;
		}

		.r5 {
			background-color: #b294b3;
		}

		.r6 {
			background-color: #b9c8bb;
		}

		.r7 {
			background-color: #ef7f19;
		}

		.r1 {
			top: 36.107731px;
			left: 0px;
			height: 27.714378px;
		}
		.r2 {
			top: 16.373095px;
			left: 14.431415px;
			height: 55.004822px;
		}
		.r4 {
			top: 5.8785186px;
			left: 29.269939px;
			height: 58.382137px;
		}
		.r5 {
			top: 36.686954px;
			left: 40.003506px;
			height: 43.313046px;
		}
		.r7 {
			top: 0px;
			left: 61.630074px;
			height: 64.109238px;
		}
		.r6 {
			top: 29.783195px;
			left: 50.592674px;
			height: 41.392502px;
		}
		.r3 {
			top: 2.9087763px;
			left: 19.829042px;
			height: 27.700764px;
		}
	}
}
</style>

