<template>
	<div class="home">
		<section class="intro">
			<img loading="lazy" decoding="async" :src="require(`@/assets/intro.png`)" alt="Grudziądzka strefa kultury">
		</section>

		<section>
			<ul id="introlinks" class="introgroup">
				<li :class="'group-item '+index" v-for:="(key, index) of $dat.categories">
					<router-link
						:class="'item'"
						:to="{ path: key.path }"
					>
						<span class="name">{{ key.name }}</span>
					</router-link>
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
// @ is an alias to /src
//	import HelloWorld from '@/components/HelloWorld.vue';



// console.log(Carousel, Slide, Pagination, Navigation);

export default {
	name: 'Home',
	components: {

	},
	data() {
		return {





		};
	},

	beforeCreate() {
		/*
		const routes = this.$router.getRoutes();

		for (let r = 0, rs = routes.length; r < rs; r += 1) {
			if (
				routes[r].props &&
				routes[r].props.default &&
				routes[r].props.default.catroot
			) {
				if (this.$dat.categories[routes[r].props.default.catroot]) {
					if (routes[r].path) {
						this.$dat.categories[routes[r].props.default.catroot].path =
							routes[r].path;
					}
				}
			}
		}
		*/
	},

	mounted() {

this.$store.catmenu = false;


		// console.log('t', this.$router.getRoutes());
	},
};
</script>
<style lang="less">
@import url(../style/mediaqueries.less);

.intro {
	padding-bottom: 20px;

	img {
		max-width: 100%;
		display: block;
	}
}
/*
body.index {
}

#intro {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.item {
		padding: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	img {
		max-width: 200px;
	}
}
*/

.introgroup {
	min-width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	padding-bottom: 20px;

	@media @tablet {
		flex-wrap: wrap;
	}

	.people {
		background-color: var(--people-back);

		&:before {
			background-color: var(--people-front);
		}
	}
	.band {
		background-color: var(--band-back);
		&:before {
			background-color: var(--band-front);
		}
	}

	.place {
		background-color: var(--place-back);
		&:before {
			background-color: var(--place-front);
		}
	}

	.media {
		background-color: var(--media-back);
		&:before {
			background-color: var(--media-front);
		}
	}

	@keyframes back {
		from {
			background-position: 0% 0%;
		}

		to {
			background-position: 100% 0%;
		}
	}

	.group-item {
		position: relative;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
		display: flex;
		min-height: 250px;
		/* overflow: hidden; */
		/*min-width: 100%; */

		@media @tablet {
			flex-shrink: 0.5;
			min-height: 100px;
		}

		&:before {
			pointer-events: none;
			opacity: 1;

			content: '';
			width: 100%;
			height: 100%;

			background-image: linear-gradient(
				80deg,
				transparent 30%,
				rgba(255, 255, 255, 1) 50%,
				transparent 70%
			);

			background-position: 0% 0%;
			background-size: 400% 100%;

			animation-fill-mode: forwards;
			animation-duration: 500ms;

			display: block;
			position: absolute;
			z-index: 0;
			mask-position: center;
			mask-image: url('../assets/mask.png');

			mask-size: cover;
		}

		&:hover {
			&::before {
				animation-name: back;

				/* background-position: 100% 0%; */
			}
		}
	}

	.item {
		text-transform: lowercase;
		text-decoration: none;
		color: inherit;
		font-size: 4.2rem;

		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
		display: flex;

		padding: 20px;
		justify-content: flex-end;
		align-items: flex-end;
		text-align: right;

		font-weight: bold;
		color: white;

		.name {
			position: relative;
			z-index: 1;
		}
	}
}

.carousel {
	.carousel__slide {
		img {
			max-height: 500px;
			max-width: 100px;
		}
	}
}
</style>


