import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import { trackRouter } from "vue-gtag-next";
import VueBodyClass from 'vue-body-class';
import category from '@/views/category.vue'
import store from '@/store/index.js'
import { nextTick } from 'vue';

function befor(befor) {



  return (true);

  /*

  // console.log('befor befor', befor);

  let targetCat = befor.matched[0].props.default.cat;

  // console.log('targetCat targetCat', targetCat)

  if (targetCat == 'uzun tego tu') {

    // console.log('BEEEEEEEEEEEEEE')

    if (!store.state.categories[targetCat]) {

      console.log('w store nie ma kategorii', targetCat)

      store.dispatch('getCategory', { "category": targetCat }).then((result) => {
        // nie udało się go zmusić do ncizego innego, żadne promises - tylko sztywne sprawdzanie czy coś jest
        //@todo to powinno zwracać że czegoś nie ma ale w tej postaci tylko zwraca do 'then' a nie do calej funkcji
        if (store.state.categories[targetCat]) {
          //console.log('zwracam true')
          return (true)

          //          next();

        } else {
          router.push('/blad-kategorii/' + targetCat);
          return (false)
        }
      })

      //@todo to jest bardo źle - to powinno sie uruchamiać po wykonaniu dispatchg i sprawdzac czy sie udalo pobieranie
      if (!store.state.categories[targetCat]) {
        //        return false;
      }
    } else {

      console.log('w store jest kategoria', targetCat)

      return (true);

    }

  }

  // console.log('befor false', befor, JSON.stringify(store.state))

  return true;

  */

}


const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      home: 'home',
      breadCrumb: 'Hello World',
      bodyClass: 'index'
    },
    component: Home
  },
  {
    path: '/dolacz',
    name: 'Dołącz do nas',
    meta: {
      breadCrumb: 'Dołącz do nas'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/dolacz.vue')
  },
  {
    path: '/policy',
    name: 'Polityka prywatności',
    meta: {
      breadCrumb: 'Polityka prywatności'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/policy.vue')
  },
  {
    path: '/projekt',
    name: 'Projekt',
    meta: {
      breadCrumb: 'Projekt'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/projekt.vue')
  },

  {
    path: '/onas',
    name: 'O nas',
    meta: {
      title: "O NAS",
      breadCrumb: 'O nas',
      leftmenu: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onas.vue')
  },

  {
    path: '/kontakt',
    name: 'Kontakt',
    meta: {
      breadCrumb: 'kontakt',
      leftmenu: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mystore" */ '../views/kontakt.vue')
  },

  {
    path: '/blad-kategorii/:category',
    name: 'Caterror',
    meta: {
      breadCrumb: 'Bład nie m takiej kategorii'
    },
    // props: route => ({ query: route.query.q }),
    props: { errorText: 'Nie ma takiej kategorii' },

    component: () => import(/* webpackChunkName: "notfound" */ '../views/404.vue')

  },

  {
    path: '/ludzie',
    name: 'Ludzie',
    beforeEnter: befor,

    meta: {
      breadCrumb: 'Osoby',
      leftmenu: true
    },
    // props: route => ({ query: route.query.q }),
    props: { cat: 'people', catroot: 'people' },

    component: () => import(/* webpackChunkName: "item-people" */ '../views/category.vue'),

  },


  {
    path: '/ludzie/:slug',
    name: 'Ludź',
    // beforeEnter: befor,
    meta: {
      breadCrumb: 'Osoba',
      leftmenu: true

    },
    // props: route => ({ query: route.query.q }),
    props: {
      cat: 'person'
    },

    component: () => import(/* webpackChunkName: "item" */ '../views/item.vue'),

  },

  {
    path: '/zespoly',
    name: 'Zespoły',
    beforeEnter: befor,
    meta: {
      breadCrumb: 'Zespoły',
      leftmenu: true
    },
    props: { cat: 'band', catroot: 'band' },
    component: () => import(/* webpackChunkName: "item-bands" */ '../views/category.vue'),
  },
  {
    path: '/zespoly/:slug',
    name: 'Zespół',
    meta: {
      breadCrumb: 'Zespół',
      leftmenu: true
    },
    props: {
      cat: 'band'
    },
    component: () => import(/* webpackChunkName: "item", webpackPreload: true */ '../views/item.vue'),
  },

  {
    path: '/miejsca',
    name: 'Miejsca',
    meta: {
      breadCrumb: 'Miejsca',
      leftmenu: true
    },
    props: { cat: 'place', catroot: 'place' },
    component: () => import(/* webpackChunkName: "item-places" */ '../views/category.vue'),
  },
  {
    path: '/miejsca/:slug',
    name: 'Miejsce',
    meta: {
      breadCrumb: 'Miejsce',
      leftmenu: true
    },
    props: {
      cat: 'place'

    },
    component: () => import(/* webpackChunkName: "item" */ '../views/item.vue'),
  },

  {
    path: '/media',
    name: 'Media',
    meta: {
      breadCrumb: 'Media',
      leftmenu: true
    },
    props: { cat: 'media', catroot: 'media' },
    component: () => import(/* webpackChunkName: "item-media" */ '../views/category.vue'),
  },
  {
    path: '/media/:slug',
    name: 'Medium',
    meta: {
      breadCrumb: 'Medium',
      leftmenu: true
    },
    props: {
      cat: 'medium'
    },
    component: () => import(/* webpackChunkName: "item" */ '../views/item.vue'),
  },


  {
    path: '/:pathMatch(.*)*',
    meta: {
      breadCrumb: {
        label: '404',
        parent: 'home'
      },
      props: { errorText: 'Nie znaleziono' },
    },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/404.vue')
  },

]

// const Page404 = () => import(/* webpackChunkName: "Page404" */ '@/components/pages/404)
// const Page503 = () => import(/* webpackChunkName: "Page503" */ '@/components/pages/503)



const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior(to, from, savedPosition) {

    // console.log('scroll', to, from, savedPosition);

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return {
        behavior: 'smooth',
        y: 0,
        x: 0
      };
    }
  }

})


/*
const vueBodyClass = new VueBodyClass(routes);


router.beforeEach((to, from, next) => {

  vueBodyClass.guard(to, next)
  // console.log('rot', "tp:", to, "from:", from, "next", next);

  // console.log('rot', "tp:", to.matched[0]);

  next();

})

*/

/*
router.resolve({
  name: 'not-found',
  params: { pathMatch: ['not', 'found'] },
}).href // '/not/found'

*/

trackRouter(router);

export default router


