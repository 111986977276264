let rechar = {
	'ę': 'e',
	'ó': 'o',
	'ą': 'a',
	'ś': 's',
	'ł': 'l',
	'ż': 'z',
	'ź': 'z',
	'ń': 'n'
}


function makeSlug(text) {


	let slug = text.toLowerCase()

	slug = slug.replace(/[ęóąśłżżźń]/gu, (char) => {
		return rechar[char]
	})

	slug = slug.replace(/[ ]/gu, (char) => {
		return '-'
	})

	return (slug)

}


export default {

	methods: {

		makeSlug


	}




}