<template>
	<div id="top">
		<div class="in">

<logo />


			<div class="group">

			<nav id="menu">
				<ul class="men">
					<li class="item"><router-link to="/projekt">projekt</router-link></li>
					<li class="item"><router-link to="/onas">o nas</router-link></li>
					<li class="item"><router-link to="/dolacz">dołącz</router-link></li>
					<li class="item"><router-link to="/kontakt">kontakt</router-link></li>
				</ul>
			</nav>

			<search></search>

			<a class="face" href="https://www.facebook.com/Grudzi%C4%85dzka-strefa-kultury-101552541928635"
				><img loading="lazy" decoding="async" alt="facebook" src="@/assets/facebook.png"
			/></a>

			</div>
		</div>
	</div>
</template>

<script>
import search from '../components/search.vue';
import logo from '../components/logo.vue';

export default {
	name: 'top',
	components: { search, logo },
};
</script>

<style lang="less">
@import url(../style/mediaqueries.less);

#top {
	padding-top: 20px;
	padding-bottom: 20px;

	& > .in {
		display: flex;

		justify-content: stretch;
		align-items: center;
	}

	.group {
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
		flex-wrap: nowrap;
		align-items: center;
		align-self: center;
		background-color: #b6c8b9;
		max-height: 50px;
	}

	.face {
		padding-left: 5px;
		padding-right: 5px;
		img {
			max-width: 30px;
		}
	}
}

#menu {
	/* display: flex; */
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 100%;

	.men {
		display: flex;
		/* flex-grow: 1; */
		/* flex-shrink: 1; */
		/* flex-basis: 100%; */
		flex-wrap: nowrap;
		justify-content: stretch;
		--padding: 15px;
	}

	.item {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;

		a {
			display: block;
			width: calc(100% - (2 * var(--padding)));
			color: white;
			padding: var(--padding);
			text-decoration: none;
			white-space: nowrap;
			text-transform: lowercase;
			text-align: center;

			&:hover {
				background-color: #eee;
				color: black;
			}
		}
	}
}

@media @tablet {
	#top {
		#logo {
			width: 100px;
		}
		.face {
			display: none;
		}
		#search {
			display: none;
		}
	}
}
</style>

