<template>
	<div id="search">
		<input
			class="search"
			type="search"
			@keydown.esc="hideResults"
			v-model="keywords"
			placeholder="Szukaj"
		/>

		<ul id="results" v-if="results.length > 0">
			<li class="item" v-for="result in results" v-bind:key="result.id">
				<router-link
					class="link"
					@click.native="hideResults(result.url)"
					:to="result.url"
					v-html="highlight(result.title)"
				></router-link>
			</li>
		</ul>
	</div>
</template>

<script>


import { useGtag } from "vue-gtag-next";


export default {
	name: 'search',
	data() {
		return {
			keywords: '',
			delay: 5000,
			results: []
		};
	},


	computed: {

	},
	directives: {

	},
	watch: {
		keywords(after, before) {

if(after != before){
			this.doResults();
}



			// console.log('werwerwerwe',after, before);
			// this.fetch();
		},
	},

	methods: {
		doResults() {

			let results = [];

			if (this.keywords) {
				let count = 0;

				for (let res in this.$dat.items) {
					if (this.$dat.items[res].name.match(new RegExp(this.keywords, 'giu'))) {
						if (count >= 10) {
							// max 10 szt
							break;
						}

						results.push({
							title: this.$dat.items[res].name,
							url: '/' + this.$dat.categories[ this.$dat.items[res].category[0]].path + '/' + res,
						});

						count += 1;
					}
				}
			}

			this.results = results;

			// return results;

			// this.posts.filter((result) => result.title.includes(this.keywords))
		},
		hideResults(url){

			 const { event,pageview } = useGtag()

 // event('search', {'value' : this.keywords});
 pageview({ page_path: "/?q="+this.keywords});
// ga('send', 'pageview', '/search_results.php?q=keyword');
			// console.log('hideResults',url);
			// console.log('hideResults',this.keywords);
			this.results = [];
		},
		highlight(text) {
			// console.log('highli');

			return text.replace(new RegExp(this.keywords, 'gi'), '<mark>$&</mark>');
		},
	},
};
</script>

<style lang="less">
#search {
	position: relative;
}

mark {
	border-radius: 3px;
	background-color: white;
}

#results {
	min-width: 100%;
	position: absolute;
	z-index: 100;
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: #ddd;
	display: flex;

	flex-direction: column;

	.link {
		display: block;
		padding: 10px;
		text-decoration: none;

		&:hover {
			background-color: #eee;
		}
	}

	.item {
		border-bottom: 1px solid black;
		margin-left: 5px;
		margin-right: 5px;

		&:last-child {
			border-bottom: 0;
		}
	}
}
</style>
